import React from 'react';

const Close = ({
  size,
  color = '#484848',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    viewBox="0 0 50 50"
    fill="none"
    height={size}
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || '#FF5C39'}
      d="M 14.40625 13 L 13 14.40625 L 23.625 25 L 13 35.59375 L 14.40625 37 L 25.0625 26.40625 L 35.6875 37 L 37.09375 35.59375 L 26.46875 25 L 37.09375 14.40625 L 35.6875 13 L 25.0625 23.59375 Z"
    ></path>
  </svg>
);

Close.defaultProps = {
  color: null,
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Close;
