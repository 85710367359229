import styled, { useTheme } from 'styled-components';
import { Box } from '@deckee/deck-hand';
import Close from '../svg/close';

export const StyledFilterOption = styled(Box).attrs({
  px: '2',
  py: '1',
  pr: '1',
  mr: '2',
  mt: '2',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
})`
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
`;

// For use between the two components
export interface FilterOptionProps {
  onClick: () => void;
  children: React.ReactNode;
  // Act like styled system
  bg?: string;
  color?: string;
}
export const FilterOption = ({
  bg,
  color,
  onClick,
  children,
  ...rest
}: FilterOptionProps) => {
  const theme = useTheme();

  const col = theme.colors[color] || color;
  const bgCol = theme.colors[bg] || bg;
  return (
    <StyledFilterOption color={col} bg={bgCol} onClick={onClick} {...rest}>
      <Box display="flex" alignItems="center" mr="2">
        {children}
      </Box>
      <Close size={14} color={col} />
    </StyledFilterOption>
  );
};

FilterOption.defaultProps = {
  bg: 'primarylight',
  color: 'primary',
};
