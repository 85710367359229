import { Box, Select } from '@deckee/deck-hand';
import { HighestStatusLevel } from '../../interfaces/trip';
import { FilterOption } from '../FilterOption/FilterOption';

export const highestStatusFilterNames = {
  [HighestStatusLevel.Sar30]: 'SAR Incident',
  [HighestStatusLevel.Overdue]: 'Overdue',
  [HighestStatusLevel.DueNow]: 'Due',
  [HighestStatusLevel.None]: 'Not due',
};

interface HighestStatusMultiSelectorProps {
  selectedStatuses: HighestStatusLevel[];
  onChange: (highestStatusFilters: HighestStatusLevel[]) => void;
}

const HighestStatusMultiSelector = ({
  selectedStatuses,
  onChange: setHighestStatusFilters,
}: HighestStatusMultiSelectorProps) => {
  return (
    <>
      <Select
        value=""
        onChange={(e) => {
          const selectedSet = new Set([...selectedStatuses, e.target.value]);

          return setHighestStatusFilters(
            // Convert selectedSet to an array
            Array.from(selectedSet).filter((status) => status || status === ''),
          );
        }}
      >
        <Select.Option selected value=""></Select.Option>
        <Select.Option selected={false} value={HighestStatusLevel.Sar30}>
          {highestStatusFilterNames[HighestStatusLevel.Sar30]}
        </Select.Option>
        <Select.Option selected={false} value={HighestStatusLevel.Overdue}>
          {highestStatusFilterNames[HighestStatusLevel.Overdue]}
        </Select.Option>
        <Select.Option selected={false} value={HighestStatusLevel.DueNow}>
          {highestStatusFilterNames[HighestStatusLevel.DueNow]}
        </Select.Option>
        <Select.Option selected={false} value={HighestStatusLevel.None}>
          {highestStatusFilterNames[HighestStatusLevel.None]}
        </Select.Option>
      </Select>

      <Box display="flex" flexWrap="wrap" pt="2">
        {selectedStatuses.map((filterId) => {
          return (
            <FilterOption
              color="primary"
              bg="primarylight"
              onClick={() => {
                setHighestStatusFilters(
                  selectedStatuses.filter((b) => b !== filterId),
                );
              }}
            >
              {highestStatusFilterNames[filterId]}
            </FilterOption>
          );
        })}
      </Box>
    </>
  );
};

export default HighestStatusMultiSelector;
