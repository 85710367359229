import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { Group } from '../interfaces/group';

interface UseGroupsProps {
  searchTerm?: string;
  types?: string[];
  groupRole?: 'member' | 'admin';
  myGroups?: boolean;
  reportable?: boolean;
}

function useGroups({
  searchTerm,
  types,
  myGroups,
  groupRole,
  reportable,
}: UseGroupsProps): {
  groups: Group[];
  isLoading: boolean;
  error: unknown;
} {
  const [groups, setGroups] = useState<Group[]>([]);
  const [error, setError] = useState<boolean>(false);

  // On first render, set the loading state to true as we know it's going to be loading soon
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const searchForGroups = useMemo(
    () =>
      debounce(
        200,
        async (
          searchTerm: string,
          types: string | string[],
          controller?: AbortController,
        ) => {
          try {
            if (controller?.signal?.aborted) {
              return;
            }

            const searchTermValue = searchTerm?.trim();

            const res = await fetch(
              `/api/groups?${qs.stringify(
                {
                  groupRole: groupRole || '',
                  searchTerm: searchTermValue || '',
                  type: types || '',
                  myGroups: myGroups ? 'true' : 'false',
                  reportable: reportable ? 'true' : 'false',
                },
                { arrayFormat: 'repeat' },
              )}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
                method: 'GET',
                signal: controller?.signal,
              },
            );

            const groupsResult = (await res.json()).payload;
            setGroups(
              groupsResult.sort((a, b) => a.name.localeCompare(b.name)),
            );
          } catch (error) {
            if (controller?.signal?.aborted) {
              // It's not an issue, another request was initiated
              return;
            }
            console.error(error.message);
            setError(true);
          } finally {
            setIsLoading(false);
          }
        },
      ),
    [],
  );

  useEffect(() => {
    if (error) {
      setError(false);
    }

    const controller = new AbortController();

    searchForGroups(searchTerm, types, controller);

    return () => {
      controller.abort();
    };
  }, [searchTerm, ...(types || [])]);

  return {
    groups,
    isLoading,
    error,
  };
}

export default useGroups;
