import { Box, Select } from '@deckee/deck-hand';
import { Group } from '../../interfaces/group';
import { FilterOption } from '../FilterOption/FilterOption';

interface GroupMultiSelectorProps {
  id?: string;
  groups: Group[];
  selectedGroupIds: Group['id'][];
  onChange: (selectedGroups: Group['id'][]) => void;
}

const GroupMultiSelector = ({
  id,
  groups,
  onChange,
  selectedGroupIds = [],
}: GroupMultiSelectorProps) => {
  return (
    <>
      <Select
        id={id}
        value=""
        onChange={(e) => {
          const selectedSet = new Set([...selectedGroupIds, e.target.value]);
          onChange(
            // Convert selectedSet to an array
            Array.from(selectedSet).filter((baseId) => baseId),
          );
        }}
      >
        <Select.Option value=""></Select.Option>
        {groups
          ?.filter((baseGroup) => selectedGroupIds.indexOf(baseGroup.id) < 0)
          .map((base) => (
            <Select.Option value={base.id} key={base.id}>
              {base.name}
            </Select.Option>
          ))}
      </Select>

      <Box display="flex" flexWrap="wrap" pt="2">
        {selectedGroupIds.map((baseId) => {
          const base = groups.find((b) => b.id === baseId);
          if (!base) {
            return;
          }
          return (
            <FilterOption
              onClick={() => {
                onChange(selectedGroupIds.filter((b) => b !== baseId));
              }}
              color="primary"
              key={base.id}
            >
              {base.name}
            </FilterOption>
          );
        })}
      </Box>
    </>
  );
};

export default GroupMultiSelector;
