import { DateTime, Duration, DurationUnit } from 'luxon';

export function convertDate(date: Date | string): DateTime {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  return DateTime.fromISO(date);
}

export function convertDateFromString(
  dateString: string,
  format: string,
): DateTime {
  return DateTime.fromFormat(dateString, format);
}

export function formatDateTime(date: Date | string): string {
  const formatStr = 'HH:mm ccc dd LLL';

  return convertDate(date).toFormat(formatStr);
}

export function formatDate(date: Date | string): string {
  const formatStr = 'ccc dd LLL';

  return convertDate(date).toFormat(formatStr);
}

export function formatDateShort(date: Date | string): string {
  const formatStr = 'dd/LL/yyyy';

  return convertDate(date).toFormat(formatStr);
}

export function formatTime(date: Date | string): string {
  const formatStr = 'HH:mm';

  return convertDate(date).toFormat(formatStr);
}

export function formatTimeDifference(
  startDate: Date | string,
  endDate: Date | string,
): string {
  const diff = convertDate(startDate).diff(convertDate(endDate), [
    'days',
    'hours',
    'minutes',
  ]);
  if (diff.days > 0)
    return `${diff.days}d ${diff.hours}h ${Math.round(diff.minutes)}m`;
  if (diff.days <= -1)
    return `+ ${Math.abs(diff.days)}d ${Math.abs(diff.hours)}h ${Math.abs(
      Math.round(diff.minutes),
    )}m`;
  if (diff.hours > 0) return `${diff.hours}h ${Math.round(diff.minutes)}m`;
  if (diff.hours <= -1)
    return `+ ${Math.abs(diff.hours)}h ${Math.abs(Math.round(diff.minutes))}m`;
  if (diff.minutes > 0) return `${Math.round(diff.minutes)}m`;
  if (diff.minutes <= -1) return `+ ${Math.abs(Math.round(diff.minutes))}m`;
  if (diff.minutes > -1 && diff.minutes <= 0) return 'Now';

  throw new Error(
    `Invalid date difference, inputs: startDate: ${startDate} endDate: ${endDate} diff: ${JSON.stringify(
      diff,
    )}`,
  );
}

export function formatMillisecondsToHuman(ms: number): string {
  // Convert milliseconds into a Duration
  const duration = Duration.fromMillis(ms).shiftTo(
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  );

  // Get a list of units and filter out those that are zero
  const units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];

  const nonZeroUnits = units
    .map((unit) => ({
      unit,
      // @ts-ignore
      value: duration.get(unit),
    }))
    .filter((item) => item.value > 0);

  const highestUnits = nonZeroUnits.slice(0, 2);

  // Helper function to add pluralization
  const formatUnit = (value: number, unit: string) =>
    `${Math.round(value)} ${unit.slice(0, -1)}${
      value > 1 ? unit.slice(-1) : ''
    }`;

  return highestUnits
    .map(({ value, unit }) => formatUnit(value, unit))
    .join(', ');
}

export function dateValidate(date: string): boolean {
  return /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
    date,
  );
}

export const convertPlannedReturnTime = (date: string, time: string): Date => {
  const [day, month, year] = date.split('/');
  return new Date(`${year}-${month}-${day} ${time}`);
};

export const calculateTimeDifference = (
  dueDate: string | Date,
  units: DurationUnit = 'minutes',
): number => {
  const currentDateTime = DateTime.now();
  const diff = convertDate(dueDate).diff(currentDateTime);

  const minutesDifferent = Math.floor(diff.as(units));

  return minutesDifferent;
};
