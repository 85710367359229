import { useUser } from '@auth0/nextjs-auth0';
import { Card, LoadingIndicator } from '@deckee/deck-hand';
import React from 'react';
import { UserPermissions } from '../lib/authorization/roles';
import Can from './can';
import RedirectToLogin from './login-redirect';

// this component should take the required permissions as props (e.g. 'task:create' or ['task:view', 'task:edit'])
const Protected = ({
  children,
  permission,
}: {
  children: React.ReactNode;
  permission?: UserPermissions;
}): React.ReactElement => {
  const { user, error, isLoading } = useUser();

  if (!isLoading && !user && !error) {
    // redirect to home
    return <RedirectToLogin />;
  }

  return (
    <>
      {isLoading && (
        <Card w="100%">
          <Card.Body>
            <LoadingIndicator />
          </Card.Body>
        </Card>
      )}
      {error && <p>{error.message}</p>}

      {/* check the required permissions here. */}
      {permission && user ? (
        <Can
          perform={permission}
          yes={() => <>{children}</>}
          no={() => <div>Unauthorized access</div>}
        />
      ) : (
        user && <>{children}</>
      )}
    </>
  );
};

Protected.defaultProps = {
  permission: null,
};

export default Protected;
